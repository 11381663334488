import React from "react";
import styled from "styled-components";
import { SwapSpinner } from "react-spinners-kit";
import SensLogo from "../images/sens-logo.png";
import RevtelLogo from "../images/revtel-logo-04.png";

import SensLogo2 from "../images/logo-desktop.png";
import RevtelLogo2 from "../images/revtel-logo2.png";

import SensLogo3 from "../images/SensLogo.png";
import RevtelLogo3 from "../images/RevLogo.png";
import RealiTLogo from "../images/realiT.png";

const DEBUG = false;

const DEST_MAP = {
    "0": "https://www.revtel.tech/",

    "11": "https://youtu.be/ruExj5-ZFeM",
    "12": "https://youtu.be/Qp_yJK4ZFj8",

    test: 'https://sl.revtel.app/?debug=1',

    sens: "https://www.sensationsprint.com.tw/",
    comfy: "https://www.revtel.tech/",
};

class LandingPage extends React.Component {
    state = {
        done: true,
    }

    componentDidMount() {
        function doJump() {
            setTimeout(() => {
                try {
                    function getParameterByName(name, url = window.location.href) {
                        name = name.replace(/[\[\]]/g, "\\$&");
                        var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
                            results = regex.exec(url);
                        if (!results) return null;
                        if (!results[2]) return "";
                        return decodeURIComponent(results[2].replace(/\+/g, " "));
                    }

                    const id = getParameterByName("id");
                    if (id != null && DEST_MAP[id]) {
                        window.ga && window.ga('send', 'event', 'CorrectID', '['+id+'] '+DEST_MAP[id])
                        window.location.href = DEST_MAP[id];
                    } else {
                        window.ga && window.ga('send', 'event', 'WrongID', '['+id+'] '+DEST_MAP['0'])
                        window.location.href = DEST_MAP["0"];
                    }
                } catch (err) {
                    window.ga && window.ga('send', 'event', 'WrongPara', window.location.href)
                    window.location.href = DEST_MAP["0"];
                }
            }, 1000 * (DEBUG || window.location.href.indexOf("debug=1") !== -1 ? 800 : 1.5));
        }

        doJump()

    }

    render() {
        let {done} = this.state

        return (
            <div style={{width:'100vw', height:'100vh', backgroundColor:'#eeeeee'}}>
                <div style={{width:'100%', height:'100%', display:'flex', alignItems:'center', justifyContent:'center', flexDirection:'column'}}>
                    <h2 style={done ? {} : {display: 'none'}}>RealiT 智慧感應，隨心跳轉</h2>

                    <a
                        href="https://www.revtel.tech"
                        style={{ color: "inherit", margin:40, textDecoration: "inherit", ...(done ? {} : { display: 'none' }) }}
                        target="_blank"
                    >
                        <img
                            src={RealiTLogo}
                            style={{ height: 80, }}
                            onLoad={() => { this.setState({ done: true }); }}
                        />
                    </a>

                    <SwapSpinner loading={done} />


                    <a
                        href="https://www.revtel.tech"
                        style={{ color: "inherit", textDecoration: "inherit", marginTop:120, color: '#FF5733', ...(done ? {} : { display: 'none' }) }}
                        target="_blank"
                    >
                        忻旅科技股份有限公司 RevtelTech
                    </a>
                </div>
            </div>
        )

    }
}

const Wrapper = styled.div`
  background-color: #eeeeee;

   height: 100% !important;
    position: fixed;
    left: 0;
    width: 100% !important;
    overflow: hidden;

`;

export default LandingPage;
